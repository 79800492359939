@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400&display=swap');

.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: #000;
  color: #fff;
  
}

.page-content {
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 auto;
  max-width: 1700px;
}


.img-fluid {
  width: 100%;
}

.card-container {
  padding: 25px 0;
}
